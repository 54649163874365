import * as adUI from 'UI/store/ui';
import { plugin as adPlugin } from 'UI/store/ui/plugin';
import sanitizeFooter from './sanitizers/footer';
import sanitizeHeader from './sanitizers/header';
import {
  LAYOUT_SET_FOOTER_DATA,
  LAYOUT_SET_HEADER_DATA,
  LAYOUT_SET_HEADER_PRO_DATA,
  LAYOUT_SET_PRO,
  SETTINGS_SET_LOCALES,
  SETTINGS_SET_BASE_URL,
  SET_REDIRECTIONS,
} from './mutation-types';
import getHeader from '@/utils/helpers/get-header';
import { routerLinks } from '@/utils/fetch-links';
import getUrl from '@/utils/helpers/get-url';
import { getEkomiData } from '@/utils/ekomi';

import { isProRoute } from '@/utils/helpers/get-is-pro-route';

const plugins = [adPlugin];

export { plugins };

export const modules = {
  ui: adUI,
};

export const state = () => ({
  redirections: [],
});

export const mutations = {
  [SET_REDIRECTIONS](mutationState, data) {
    mutationState.redirections = data;
  },
};

export const fetchHeader = async (
  $prismic,
  commit,
  { i18n, $enhancedLinkSerializer, route },
  lang,
  isPro,
  languages
) => {
  try {
    const header = await getHeader($prismic, lang, { isPro });
    const commitValue = !isPro
      ? `layout/${LAYOUT_SET_HEADER_DATA}`
      : `layout/${LAYOUT_SET_HEADER_PRO_DATA}`;

    commit(
      commitValue,
      sanitizeHeader(
        { data: header.data, modules: header.modules, languages, lang },
        { i18n, $enhancedLinkSerializer, route }
      )
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('An error occured during header fetch.', e);
  }
};

export const fetchFooter = async (
  $prismic,
  commit,
  { i18n, $enhancedLinkSerializer, route },
  isPro,
  languages,
  lang
) => {
  try {
    const { data: footerData } = await $prismic.api.getByUID(
      'module_footer',
      isPro ? 'footer-pro' : 'footer',
      {
        fetchLinks: routerLinks,
        lang,
      }
    );

    commit(
      `layout/${LAYOUT_SET_FOOTER_DATA}`,
      sanitizeFooter(
        { ...footerData, languages },
        { $enhancedLinkSerializer, i18n }
      )
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('An error occured during footer fetch.', e);
  }
};

const getLanguages = (locales, lang, i18n) => {
  return locales.map((locale) => {
    return {
      code: locale.code,
      iso: locale.iso,
      label: i18n.t(`configs.locales.${locale.iso}`),
      isCurrent: locale.iso === lang,
      prefixIndividual: locale.individual,
      prefiCorporate: locale.corporate,
    };
  });
};

export const actions = {
  async nuxtServerInit({ commit }, context) {
    const { $prismic, i18n, $enhancedLinkSerializer, route, req } = context;
    const {
      locales,
      localeProperties: { iso: lang },
    } = i18n;

    const languages = getLanguages(locales, lang, i18n);

    // Theme
    const isPro = isProRoute(context.route);
    commit(`layout/${LAYOUT_SET_PRO}`, isPro);

    // Locales

    if (req && req.headers && req.headers.host) {
      const root = getUrl(req);
      commit(`settings/${SETTINGS_SET_BASE_URL}`, root);
    }

    // Locales
    try {
      commit(`settings/${SETTINGS_SET_LOCALES}`, languages);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('An error occured storing locales.', e);
    }

    // Redirections
    try {
      const redirectionsData = await $prismic.api.query(
        $prismic.predicates.at('document.type', 'redirect')
      );
      const redirections = redirectionsData.results.map((result) => ({
        from: result.data.redirect_from,
        to: result.data.redirect_to,
      }));
      commit(`${SET_REDIRECTIONS}`, redirections);
    } catch (e) {
      console.warn('An error occured during redirections fetch.', e);
    }

    // Header Data
    await fetchHeader(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, route },
      lang,
      isPro,
      languages
    );

    // Footer Data
    await fetchFooter(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, route },
      isPro,
      languages,
      lang
    );

    // Ekomi Data
    await getEkomiData(null, 'reviews', context);
  },

  async loadHeader({ commit }, context) {
    const { $prismic, $i18n: i18n, $enhancedLinkSerializer, $route } = context;
    const isPro = isProRoute($route);
    const {
      locales,
      localeProperties: { iso: lang },
    } = i18n;
    const languages = getLanguages(locales, lang, i18n);

    const shouldLoad = !isPro
      ? !context.$store.state.layout.header.private
      : !context.$store.state.layout.header.pro;
    const headerKey = isPro ? 'pro' : 'private';
    const langSwitch =
      context.$store.state.layout.header[headerKey]?.rootLink?.field?.lang !==
      lang;

    if (!langSwitch && !shouldLoad) {
      return;
    }

    await fetchHeader(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, $route },
      lang,
      isPro,
      languages
    );
  },

  async loadFooter({ commit }, context) {
    const { $prismic, $i18n: i18n, $enhancedLinkSerializer, $route } = context;
    const isPro = isProRoute($route);
    const {
      locales,
      localeProperties: { iso: lang },
    } = i18n;
    const languages = getLanguages(locales, lang, i18n);

    await fetchFooter(
      $prismic,
      commit,
      { i18n, $enhancedLinkSerializer, locales },
      isPro,
      languages,
      lang
    );
  },
};
