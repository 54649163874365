
import { isProRoute } from '@/utils/helpers/get-is-pro-route';
import { LAYOUT_SET_PRO } from '@/store/mutation-types';
import { pushPageView } from '~/plugins/gtm';

export default {
  name: 'AppLayout',
  components: {
    SiteHeader: () => import('@/components/header'),
    LisaModulePanel: () => import('@/components/lisa-module-panel'),
  },
  data() {
    return {
      isSitePro: isProRoute(this.$route),
      withLisaModule: this.$config.lisaEnabled,
    };
  },
  head() {
    const locale = this.$i18n.localeProperties;
    return {
      htmlAttrs: {
        lang: locale.code,
      },
      bodyAttrs: {
        class: this.isSitePro ? 'theme-corporate' : 'theme-individual',
      },
    };
  },
  computed: {
    lisaUId() {
      const header = this.isPro
        ? this.$store.state.layout.header.pro
        : this.$store.state.layout.header.private;
      const lisaAction = header?.actions?.find((action) => action.lisaUId);
      return lisaAction?.lisaUId || null;
    },
    layout() {
      return this.$store.state.layout;
    },
    isPro() {
      return this.$store.state.layout.isPro;
    },
    altLanguagesData() {
      return this.$store.state.layout.altLanguages;
    },
    languages() {
      return this.$store.state.settings.languages;
    },
    locale() {
      return this.$i18n.localeProperties.iso;
    },
    topHeaderHeight: function () {
      return this.$store.state.layout.topHeader?.height || 0;
    },
    style: function () {
      return {
        '--top-header-height': `${this.topHeaderHeight}px`,
      };
    },
  },
  watch: {
    async locale() {
      await this.$store.dispatch('loadHeader', this);
      await this.$store.dispatch('loadFooter', this);
      window &&
        window.axeptioSDK.setCookiesVersion(
          this.$i18n?.localeProperties.code?.toUpperCase()
        );
    },
    $route(to) {
      pushPageView({ $gtm: this.$gtm, route: this.$route, store: this.$store });
      const isPro = isProRoute(to);
      this.isSitePro = isPro;
      this.$store.commit(`layout/${LAYOUT_SET_PRO}`, isPro);
      if (this.$store.state.ui.overlay.opened)
        this.$store.commit('ui/toggleOverlay');
    },
    isSitePro() {
      this.$store.dispatch('loadHeader', this);
      this.$store.dispatch('loadFooter', this);
    },
  },
  methods: {
    handleOpenPanel(panel) {
      this.$nextTick(() => {
        if (panel === 'lisa_panel')
          if (this.$refs.formLisaPanel.$refs?.lisa) {
            this.$refs.formLisaPanel.$refs?.lisa?.handleToggle();
            this.$refs.formLisaPanel.pingAutoFill();
          } else {
            this.$refs.formLisaPanel.$refs?.lisaPanel?.$refs?.lisa?.handleToggle();
          }
      });
    },
    onLanguageSwitched(switchedLocale) {
      const locale = switchedLocale.slice(0, 2);

      // get new locale  data
      const localeData = this.languages.find(
        (locale) => locale.iso === switchedLocale
      );

      // get localized prefix
      const prefix = this.isPro ? localeData.prefixCorporate : '';

      // check if current page has an existing version in the switched locale
      const localizedPageData = this.altLanguagesData?.find(
        (data) => data.lang === switchedLocale
      );

      if (!localizedPageData) {
        this.$router.push(`/${locale}/${prefix}`);
      } else {
        this.$router.push(this.switchLocalePath(locale));
      }
    },
    openCookies() {
      window._axcb.push(function (sdk) {
        sdk.openCookies();
      });
    },
  },
};
