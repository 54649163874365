import linkSerializer from './link-serializer';
import { isProRoute } from '@/utils/helpers/get-is-pro-route';

export default ({ route, store, i18n }, inject) => {
  const isPro = isProRoute(route);

  inject('enhancedLinkSerializer', (link, isDocument) =>
    linkSerializer(link, isPro, isDocument, store)
  );
};
