export const routerLinks = [
  'product_page.offer',
  'page_guide.offer',
  'page_guide.parent',
  'page_guides.offer',
  'page_country.region',
  'page_form.page_type',
  'page.page_type',
  'page.parent',
  'page_form.offer',
  'page_form.product',
  'page_article.market',
  'module_lisa.new_lisa',
];

export const pageLinks = ['page.title', 'page.breadcrumb_title', 'page.parent'];

export const offerLinks = [
  'page_offer.title',
  'page_offer.title_tracking',
  'page_offer.card',
  'page_offer.breadcrumb_title',
  'page_offer.primary_link',
  'page_offer.primary_link_label',
  'offer.title',
  'offer.card_title',
  'offer.card_image',
  'offer.card_image_alt',
  'offer.offer_link',
];

export const cardLinks = [
  'module_card.card_title',
  'module_card.card_image',
  'module_card.card_image_alt',
  'module_card.card_link',
];

export const regionLinks = [
  'page_region.uid',
  'page_region.title',
  'page_region.breadcrumb_title',
  'page_region.card',
  'page_region.card_title',
  'page_region.card_image',
  'page_region.card_image_alt',
];

export const countryLinks = [
  'page_country.title',
  'page_country.breadcrumb_title',
  'page_country.region',
  'page_country.card',
  'page_country.card_title',
  'page_country.card_image',
];

export const productLinks = [
  'product_page.title',
  'product_page.breadcrumb_title',
  'product_page.tag',
  'product_page.starting_price',
  'product_page.price_frequency',
  'product_page.currency',
  'product_page.legal_reference',
  'product_page.offer',
  'product_page.card',
  'product_page.product_data',
];

export const contactLinks = [
  'module_contact.contact_subhead',
  'module_contact.contact_title',
  'module_contact.contact_phone_label',
  'module_contact.contact_phone_number',
  'module_contact.contact_description',
  'module_contact.contact_inline_description',
  'module_contact.contact_mention',
];
export const ratingLinks = [
  'module_rating.title',
  'module_rating.text',
  'module_rating.average_rating',
  'module_rating.badge',
  'module_rating.badge_alt',
  'module_rating.view_all_link',
  'module_rating.view_all_label',
  'module_rating.ratings',
];

export const guideLinks = [
  'page_guide.title',
  'page_guide.breadcrumb_title',
  'page_guide.excerpt',
  'page_guide.date',
  'page_guide.offer',
  'page_guide.reading_time',
  'page_guide.parent',
];

export const articleLinks = [
  'page_article.title',
  'page_article.breadcrumb_title',
  'page_article.image',
  'page_article.image_alt',
  'page_article.date',
  'page_article.market',
  'page_article.reading_time',
];

export const lisaLinks = [
  'module_lisa.uid',
  'module_lisa.title',
  'module_lisa.description',
  'module_lisa.image',
  'module_lisa.cta_submit_label',
  'module_lisa.cta_label',
  'module_lisa.cta_link',
  'module_lisa.cover_type',
  'module_lisa.cover_type_tooltip',
  'module_lisa.destination_tooltip',
  'module_lisa.language_tooltip',
];

export const coverTypeLinks = [
  'cover_types.title',
  'cover_types.description',
  'cover_types.icon',
];

export const topHeaderLinks = [
  'module_top_header.text',
  'module_top_header.link',
  'module_top_header.link_label',
];

export const formMailToLinks = [
  'module_form_mailto.confirmation_link',
  'module_form_mailto.confirmation_secondary_link',
];
